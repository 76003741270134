
import { defineComponent } from 'vue';
import { Lesson, Translation, errorPrepend } from '@/models';
import { languageMixin } from '@/mixins/languageMixin';
import { showError } from '@/sweetalert2/templates.ts';
import LessonDetailsHeader from '@/views/teacher/LessonDetailsHeader.vue';
import TranslationImage from '@/components/common/TranslationImage.vue';
import NoContent from '@/components/common/NoContent.vue';
import SortCaret from '@/components/common/SortCaret.vue';
import sortMixin from '@/mixins/sortMixin';
import { lessonDetailsMixin } from '@/mixins/lessonDetailsMixin';

export default defineComponent({
  components: { SortCaret, NoContent, TranslationImage, LessonDetailsHeader },
  mixins: [languageMixin, sortMixin, lessonDetailsMixin],

  data () {
    return {
      editingMode: false,
      newTranslation: { fromName: '', toName: '' },
      imagesToUpload: [] as { translation: Translation, file: File, license: string }[]
    };
  },
  watch: {
    currentSortDir () {
      this.sortTranslations();
      if (this.editingMode) {
        this.removeEmptyLines();
        this.addNewLine();
      }
    },
    currentSort () {
      this.sortTranslations();
      if (this.editingMode) {
        this.removeEmptyLines();
        this.addNewLine();
      }
    }
  },
  methods: {
    deleteTranslation (fromName: string, toName: string) {
      if (this.translations.length === 1) {
        return;
      }
      this.translations = this.translations.filter(t => t.fromName !== fromName || t.toName !== toName);
      if (this.halfEmptyTranslation(this.firstTranslation)) {
        this.addNewLine();
      }
    },
    addNewLine () {
      if (this.translations.length !== 0 && (this.emptyTranslation(this.firstTranslation) || this.firstTranslation.newTranslation)) {
        return;
      }
      let highestId = this.translations.reduce((a, b) => Math.max(a, b.fromId, b.toId), 0);
      this.translations.unshift({ fromName: '', fromId: ++highestId, toName: '', toId: ++highestId } as Translation);
      this.firstTranslation.newTranslation = true;
      const table = this.$refs.table as HTMLTableElement;
      setTimeout(() =>
        (table.rows[1].cells[0].firstChild as HTMLInputElement).focus(), 10);
    },
    removeEmptyLines () {
      this.translations = this.translations.filter(t => !this.emptyTranslation(t));
    },
    toggleEdit () {
      this.editingMode = !this.editingMode;
      this.removeEmptyLines();
      if (this.editingMode) {
        this.addNewLine();
      }
    },
    sortTranslations () {
      this.translations.sort((a, b) => {
        const modifier = this.currentSortDir === 'desc' ? -1 : 1;
        if (this.halfEmptyTranslation(a) || a.newTranslation) {
          return -1;
        } else if (this.halfEmptyTranslation(b) || b.newTranslation) {
          return 1;
        }
        if (this.currentSort in a) {
          const aVal = a?.[this.currentSort as keyof Translation] as string;
          const bVal = b?.[this.currentSort as keyof Translation] as string;
          return ('' + aVal).toLowerCase().localeCompare(('' + bVal).toLowerCase()) * modifier;
        }

        return 0;
      });
    },
    addTranslation (translation: Translation) {
      if (!this.emptyTranslation(translation)) {
        translation.newTranslation = false;
        this.removeEmptyLines();
        this.addNewLine();
      }
    },
    saveWithEnter (translation: Translation, $event: KeyboardEvent) {
      if ($event.key !== 'Enter') {
        return;
      }
      this.addTranslation(translation);
    },
    saveToServer ($event: string) {
      this.lesson.name = $event;
      this.toggleEdit();
      this.removeEmptyLines();
      this.updateLesson();
    },
    async updateLesson () {
      this.translations = this.translations.filter(t => t.fromName.length && t.toName.length);
      const dataToSend = this.translations.map(t => {
        return { vocabFrom: t.fromName, vocabTo: t.toName, imageUrl: !t.imageUrl || t.imageUrl === '' || t.imageUrl.includes('blob') ? null : t.imageUrl, imageDescription: t.imageDescription };
      });
      try {
        await Lesson.update(this.lesson);
        await Translation.updateLessonTranslations(this.lesson.id, dataToSend);
        await this.getData(this.lesson.id);
        this.sortTranslations();
        await this.uploadMissingImages();
      } catch (e) {
        await showError(this.$t("messages.no lesson change") + errorPrepend());
      } finally {
        await Lesson.downloadAudios(this.lesson.id);
      }
    },
    emptyTranslation (translation: Translation) {
      return translation.fromName.length + translation.toName.length === 0;
    },
    halfEmptyTranslation (translation: Translation) {
      return translation.fromName === '' || translation.toName === '';
    },
    cancel () {
      this.toggleEdit();
      this.removeEmptyLines();
      this.imagesToUpload = [];
      this.getData(this.lesson.id);
    },
    async addImage ($event: { translation: Translation, file: File, index: number, license: string }) {
      this.translations[($event.index)].image = $event.file;
      this.translations[($event.index)].imageDescription = $event.license;
      this.translations[$event.index].imageUrl = URL.createObjectURL($event.file);
      if ($event.translation.newTranslation) {
        this.imagesToUpload.push({ translation: $event.translation, file: $event.file, license: $event.license });
        return;
      }
      try {
        const data = await Lesson.uploadImage($event.translation, this.lesson.id, $event.file, $event.license);
        this.translations[$event.index].imageUrl = data.imageUrl;
      } catch (e) {
        await showError(this.$t("messages.no lesson change") + errorPrepend());
      }
    },
    addCsvFile ($event: string[][]) {
      $event.forEach(t => !this.translations.some(t2 => t2.fromName === t[0] && t2.toName === t[1])
        ? this.translations.push({ fromName: t[0], toName: t[1], newTranslation: true } as Translation) : null);
    },
    uploadMissingImages () {
      this.imagesToUpload.forEach((i, imgIndex) => {
        const translation = this.translations.find(t => t.fromName === i.translation.fromName && t.toName === i.translation.toName);
        if (translation === undefined) {
          this.imagesToUpload.splice(imgIndex, 1);
          return;
        }
        const index = this.translations.findIndex(t => t.fromId === translation.fromId && t.toId === translation.toId);
        this.addImage({ file: i.file, translation, index, license: i.license });
      });
    }
  },
  computed: {
    firstTranslation (): Translation {
      return this.translations.length ? this.translations[0] : {} as Translation;
    }
  }
});
