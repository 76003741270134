
import { defineComponent } from 'vue';
import { csvMixin } from '@/mixins/csvFile';

export default defineComponent({
  mixins: [csvMixin],
  emits: ['uploadedTranslations'],
  props: {
    languageFrom: {
      type: String,
      default: 'Deutsch'
    },
    buttonText: {
      type: String,
      default: 'CSV-Datei hochladen'
    }
  },
  methods: {
    async save () {
      const fileInput = this.$refs.vocabFile as HTMLInputElement;
      let outputs = await this.saveFiles(fileInput);
      outputs = outputs.sort((o1, o2) => o1.file.name.localeCompare(o2.file.name));
      this.emitEvent(outputs);
      (this.$refs.modalDismiss as HTMLButtonElement).click();
    },
    emitEvent (outputs: {translations: string[][], file: File}[]) {
      this.$emit('uploadedTranslations', outputs);
    }
  }
});
