
import { defineComponent } from 'vue';
import { csvMixin } from '@/mixins/csvFile';
import CsvModal from '@/components/teacher/CsvModal.vue';

export default defineComponent({
  name: 'LessonDetailsHeader',
  components: { CsvModal },
  mixins: [csvMixin],
  data () {
    return {
      name: ''
    };
  },
  mounted () {
    this.name = this.lessonName;
  },
  watch: {
    lessonName (newVal:string) {
      this.name = newVal;
    }
  },
  props: {
    lessonName: {
      type: String,
      required: true
    },
    editingMode: {
      type: Boolean
    }
  },
  emits: ['toggleEdit', 'saveToServer', 'addCsvFile', 'cancelEdit'],

  methods: {

    saveToServer () {
      this.$emit('saveToServer', this.name);
    },
    toggleEdit () {
      this.$emit('toggleEdit');
    },
    cancel () {
      this.$emit('cancelEdit');
    },
    uploadedCsv ($event: {translations: string[][], file: File}[]) {
      $event.forEach(f => this.$emit('addCsvFile', f.translations));
    }
  }
});
